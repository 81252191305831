import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAffectation_v4Settings } from 'src/hooks/useAffectation_v4';
import { Box, CircularProgress, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { AFFECT_TYPE } from 'src/constants/affectation';
import { Affectation_Setting_Type, Affectation_v4_Type } from 'src/models/Affectation_v4_type';

import AffectionForm from 'src/section/affectation_v4/AffectionForm';
import { getCourielById, updateAffectation } from 'src/redux/slices/affectation_v4';
import useAuth from 'src/hooks/useAuth';
import AffectationDetails from 'src/section/affectation_v4/AffectationDetails';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import TextMaxLine from 'src/components/TextMaxLine';
import Iconify from 'src/components/Iconify';
import { useSnackbar } from 'notistack';

const Affection_v4ContextProvider = createContext({
  openForm: (formType) => { },
  openFormById: (id) => { },
  /**@type {Affectation_Setting_Type} */
  settings: null,
  loading: false,
  /** @param {'toDoIds' | 'toReviewIds' | 'historyIds'} type */
  setAffectationTabType: (type) => { },
  /** @type {'toDoIds' | 'toReviewIds' | 'historyIds'}  */
  affectationTabType: 'toDoIds',
  /** @type {Array<Affectation_v4_Type} */
  /** @param {Affectation_v4_Type} affectation */
  handleOpenDetails: (affectation, disabled) => { },
  isUploading: false,
  /** @param {boolean} value */
  getLoading: (id) => { },
  /** @param {string} id, value */
  handleSetIsUploading: (id, value) => { },
  /** @param {Affectation_v4_Type} newAffect, oldAffect */
  handleUpdateAffectation: ({ newAffect, oldAffect }) => { },
  search: '',
  /** @param {string} value */
  setSearch: (value) => { },
  /** @param {string} value */
  filter: {
    assigne: [],
    due: [],
    priority: null
  },
  /** @param {Array<string>} value */
  handleAssigneeFilterChange: (value) => { },
  /** @param {Array<string>} value */
  handleDueFilterChange: (value) => { },
  /** @param {Array<string>} value */
  handlePriorityFilterChange: (value) => { },
  searchBy: {
    recordNumber: true,
    object: false,
    correspondant: false
  },
  /** @param {Event} event */
  handleChangeSearchBy: (event) => { },
  /** @param {Event} event */
  handleClearFiltersAndSearch: () => { },

});

export const useAffectation_v4Context = () => useContext(Affection_v4ContextProvider);

export default function Affectation_v4Provider({ children }) {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const [affectationTabType, setAffectationTabType] = useState('toDoIds');
  const [minimize, setMinimize] = useState(false);
  const { settings, loading: settingLoading } = useAffectation_v4Settings();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState([]);
  const [openDetails, setOpenDetails] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [shouldOpenDetails, setShouldOpenDetails] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    assigne: [],
    due: [],
    priority: null
  });

  const [searchBy, setSearchBy] = useState({
    recordNumber: true,
    object: false,
    correspondant: false
  });


  const openedDetails = useMemo(() => {
    return openDetails.filter((one) => one.modalState === 'open')[0];
  }, [openDetails]);


  const handleChangeSearchBy = (event) => {
    // il doit y avoir au moin un checkbox de selectionné 

    setSearchBy((old) => {
      const { name, checked } = event.target;
      if (checked) {
        return { ...old, [name]: checked };
      }
      else {
        const keys = Object.keys(old);
        if (keys.filter((key) => old[key]).length > 1) {
          return { ...old, [name]: checked };
        }
        return old;
      }
    }
    );

  };

  const handleClearFiltersAndSearch = () => {
    setSearch('');
    setFilter({
      assigne: [],
      due: [],
      priority: null
    });
  };


  // console.log('openDetails', openDetails, 'openedDetails', openedDetails);

  const handleOpen = (type) => {
    setOpen((old) => [...old, { id: old.length, type }]);
  };

  const handleClose = (position) => {
    setOpen((old) => {
      let _old = [...old];
      _old.splice(position, 1);
      return _old;
    });
  };

  const handleAssigneeFilterChange = (value) => {
    setFilter((old) => ({ ...old, assigne: value }));
  };

  const handleDueFilterChange = (value) => {
    setFilter((old) => ({ ...old, due: value }));
  };

  const handlePriorityFilterChange = (value) => {
    setFilter((old) => ({ ...old, priority: value }));
  };


  const handleMinimize = (id) => {
    setOpenDetails((old) => old.map((one) => (one.id === id ? { ...one, modalState: 'minimize' } : one)));
  };

  const handleDisMinimize = (id = null) => {
    handleMinimizeAll();
    setOpenDetails((old) => old.map((one) => (one.id === id ? { ...one, modalState: 'open' } : one)))
  };

  const handleSetIsUploading = (id, value) => {
    setOpenDetails((old) => old.map((one) => (one.id === id ? { ...one, isUploading: value } : one)));
  };

  const getLoading = (id) => {
    return openDetails.find((one) => one.id === id)?.isUploading;
  };

  const handleCloseDetails = (id) => {
    //remove the affectation from the list
    setOpenDetails((old) => old.filter((one) => one.id !== id));
  };

  const handleMinimizeAll = () => {
    setOpenDetails((old) => old.map((one) => ({ ...one, modalState: 'minimize' })));
  };
  const handleOpenDetails = useCallback((affect, disabled = false) => {
    //if one affectation is already open minimize all
    if (openDetails.find((one) => one.modalState === 'open')) {
      handleMinimizeAll();
    }
    //if the affectation is already in the list make it open
    setDisabled(disabled);
    if (openDetails.find((one) => one.id === affect.id)) {
      setOpenDetails((old) => old.map((one) => (one.id === affect.id ? { ...one, modalState: 'open' } : one)));
      return;
    }
    setOpenDetails((old) => [...old, { ...affect, modalState: 'open', disabled: disabled, isUploading: false }]);
  }, [openDetails]);

  const handleUpdateAffectation = ({ newAffect, oldAffect }) => {
    handleSetIsUploading(oldAffect.id, true);
    dispatch(
      updateAffectation({
        newAffectation: newAffect,
        oldAffectation: oldAffect,
        callback: () => {
          enqueueSnackbar('Affectation enregistrée avec succès', { variant: 'success' });
          handleCloseDetails(oldAffect.id);
          handleSetIsUploading(oldAffect.id, false);
        },
        onError: () => {
          enqueueSnackbar("Erreur lors de l'enregistrement de l'affectation", { variant: 'error' });
          handleCloseDetails(oldAffect.id);
          handleSetIsUploading(oldAffect.id, false);
        }
      })
    );
  };

  const handleOpenById = (id) => {
    dispatch(
      getCourielById(id, (data) => {
        setShouldOpenDetails(data);
      })
    );
  };

  useEffect(() => {
    if (shouldOpenDetails) {
      handleOpenDetails(shouldOpenDetails);
      setShouldOpenDetails(false);
    }
  }, [handleOpenDetails, shouldOpenDetails]);

  const store = {
    openForm: handleOpen,
    openFormById: handleOpenById,
    settings,
    loading: settingLoading,
    setAffectationTabType,
    affectationTabType,
    handleOpenDetails,
    getLoading,
    handleSetIsUploading,
    setIsUploading,
    search,
    setSearch,
    filter,
    handleAssigneeFilterChange,
    handleDueFilterChange,
    handlePriorityFilterChange,
    searchBy,
    handleChangeSearchBy,
    handleClearFiltersAndSearch,
    handleUpdateAffectation
  };

  return (
    <Affection_v4ContextProvider.Provider value={store}>
      {children}
      {Boolean(open.length) &&
        open.map((one, index) => (
          <AffectionForm
            key={one.id + 'aff'}
            open={Boolean(one)}
            formId={one.id}
            onClose={() => handleClose(index)}
            isEnter={one.type === AFFECT_TYPE.Enter}
          />
        ))}
      {
        openDetails?.filter((one) => one.modalState === 'minimize')?.reverse()?.slice(0, 3)?.map((one, index) => (
          <MinimizeForm
            key={one.id}
            affectation={one}
            desc={'Enregistrement en cours ...'}
            onClose={() => handleCloseDetails(one.id)}
            onMaximize={() => handleDisMinimize(one.id)}
            uploadings={one?.uploadings}
            index={index}
          />
        ))
      }
      {
        (Boolean(openedDetails) && (
          <AffectationDetails
            open={Boolean(openedDetails)}
            onClose={() => handleCloseDetails(openedDetails.id)}
            affectation={openedDetails}
            disabled={disabled}
            handleMinimize={() => handleMinimize(openedDetails.id)}
          />
        ))
      }
    </Affection_v4ContextProvider.Provider>
  );
}

/**
 * Renders a minimized form component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Affectation_v4_Type} props.affectation - The affectation object.
 * @param {string} props.desc - The description of the form.
 * @param {Function} props.onClose - The function to handle the close event.
 * @param {Function} props.onMaximize - The function to handle the maximize event.
 * @param {number} props.uploadings - The number of uploadings.
 * @param {number} props.index - The index of the form.
 * @returns {JSX.Element} The rendered component.
 */
const MinimizeForm = ({ affectation, desc, onClose, onMaximize, uploadings, index }) => {
  const constraintsRef = useRef();

  const right = useMemo(() => {
    if (index === 0) {
      return 50;
    }
    else if (index < 3) {
      return 50 + index * 350;
    }
    else {
      return 50 + 3 * 3150;
    }
  }, [index]);

  return createPortal(
    <Box
      ref={constraintsRef}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 1001,
        position: 'absolute',
        bottom: 0,
        right: right,
        p: 5,
        overflow: 'hidden'
      }}
    >
      <Paper
        component={motion.div}
        dragConstraints={constraintsRef}
        drag
        variant="elevation"
        elevation={10}
        sx={{ color: '#fff', width: '330px' }}
      >
        <Stack
          width={1}
          bgcolor="neutral.main"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          px={1}
          py={1}
          sx={{
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6
          }}
        >
          <TextMaxLine fontSize={15} fontWeight="bold" line={1}>
            Affectation N° &nbsp;
            {affectation?.save_reference || 'Numéro de référence'}
          </TextMaxLine>

          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Agrandir" placement="top" arrow>
              <IconButton onClick={onMaximize} sx={{ p: 0 }}>
                <Iconify icon="humbleicons:maximize" sx={{ height: 17, width: 17 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Fermer" placement="top" arrow>
              <IconButton onClick={onClose} sx={{ p: 0 }}>
                <Iconify icon="ep:close-bold" sx={{ height: 17, width: 17 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} width={1} p={1}>
          <Stack>
            <CircularProgress size="sm" variant="soft" color="info" />
            {Boolean(uploadings) && (
              <Typography color="info.main" fontSize={10} fontWeight="bold">
                {uploadings * 100}%
              </Typography>
            )}
          </Stack>
          <TextMaxLine color="#000" fontSize={13} fontWeight="bold" line={1}>
            {desc}
          </TextMaxLine>
        </Stack>
      </Paper>
    </Box>,
    document.body
  );
};