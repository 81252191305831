import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _, { uniq } from 'lodash';
import { Sheet } from '@mui/joy';
import { Dialog, DialogContent, DialogTitle, Divider, MenuItem, Select, Stack, Typography } from '@mui/material';
import LabelStyle from 'src/components/LabelStyle';
import useAuth from 'src/hooks/useAuth';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import AddProjectToTask from '../components/AddProjectToTask';
import { fr as LocalFr } from 'date-fns/locale';
import TaskAssigne from '../components/TaskAssigne';
import TaskDescriptionInput from '../components/TaskDescriptionInput';
import TaskDueDate from '../components/TaskDueDate';
import TaskFileView from '../components/TaskFileView';
import TaskSubTask from '../components/TaskSubTask';
import TaskTitleInput from '../components/TaskTitleInput';
import TaskItemDetailToolbar from './TaskItemDetailToolbar';
import { dispatch, useSelector } from 'src/redux/store';
import { getTaskById } from 'src/redux/slices/kanban';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { useGetProjectRightForTask } from 'src/section/project/v4/content/project_content';
import Scrollbar from 'src/components/Scrollbar';
import { useMarkAsReadNotificationById } from 'src/hooks/useNotifications';
import { NOTIFICATION_TYPES } from 'src/constants';
import KanbanTaskDetailsComment from '../kanban/KanbanTaskDetailsComment';
import { V4_TASK_HISTORY_ACTION_CONVERT, V4_TASK_HISTORY_ACTION } from 'src/constants/task';
import { formatDistanceToNowStrict } from 'date-fns';
import { fDate, gDate } from 'src/utils/formatTime';
import { getSelectedProjectRights } from 'src/utils/project';
import { TaskType } from 'src/models/task_m';
import { useGetTaskById } from 'src/hooks/_tasks/useGetTasks';

export const SELECT_OPTIONS = {
  SIMPLE: 'SIMPLE',
  ALL: 'ALL'
}


/**
 * Renders a line item in the task history, displaying the action taken, the user who performed the action, the date/time of the action, and any relevant details about the action.
 *
 * @param {Object} props - The component props.
 * @param {TaskType} props.task - The task object containing the history information.
 * @returns {JSX.Element} - The rendered task history line item.
 */
export const TaskHistoryLine = ({ task }) => {

  let description = "";
  switch (task?.actionType) {
    case V4_TASK_HISTORY_ACTION.CREATED:
      description = ` le ${fDate(task?.createdAt)}`;
      break;
    case V4_TASK_HISTORY_ACTION.DONE:
      description = ` le ${fDate(task?.createdAt)}`
      break;
    case V4_TASK_HISTORY_ACTION.UNDONE:
      description = ` le ${fDate(task?.createdAt)}`
      break;
    case V4_TASK_HISTORY_ACTION.PRIORITY_CHANGED:
      description = ` de ${task?.values?.old} à ${task?.values?.new}`;
      break;
    case V4_TASK_HISTORY_ACTION.DUE_DATE_CHANGED:
      description = ` du ${fDate(task?.values?.old?.[1])} au ${fDate(task?.values?.new?.[1])}`;
      break;
    case V4_TASK_HISTORY_ACTION.ASSIGNED:
      description = ` à ${task?.values?.new.map((el) => el.name).join(", ")} le ${fDate(task?.createdAt)}`;
      break;
    default:
      break;
  }
  return (
    <Stack key={task?.id} width={1} direction="row" spacing={2} sx={{ py: 0.5 }}>
      <Typography variant="subtitl2" sx={{ fontSize: 12, fontWeight: 600 }}>
        {task?.createdBy?.name}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontSize: 12, fontWeight: 500 }}>
        {V4_TASK_HISTORY_ACTION_CONVERT[task?.actionType]}

      </Typography>
      <Typography variant="subtitle2" sx={{ fontSize: 12, fontWeight: 900 }}>
        {description}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontSize: 10, fontWeight: 500 }}>
        il y a &nbsp;
        {formatDistanceToNowStrict(gDate(task?.createdAt), { addSuffix: false, locale: LocalFr })}
      </Typography>
    </Stack>
  );
}


/**
 * Renders the detailed view of a task item, including its title, project, due date, assignees, description, attachments, and comments.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the task item detail dialog is open.
 * @param {function} props.onClose - Callback function to close the task item detail dialog.
 * @param {TaskType} props.task - The task object.
 * @param {boolean} props.openAttachements - Whether the attachments lightbox should be opened.
 * @param {function} props.setOpenAttachements - Callback function to set the openAttachements state.
 * @returns {JSX.Element} - The rendered task item detail component.
 */
const TaskItemDetail = ({ open, onClose, task: _task, openAttachements, setOpenAttachements }) => {
  //TODO : add disable statement to all component
  const { user } = useAuth();
  const [currentProject, setCurrentProject] = useState(null);
  const [view, setView] = useState(SELECT_OPTIONS.ALL);
  const { currentProject: _currentProject } = useProjectContext();
  const taskRights = useGetProjectRightForTask(_currentProject, user);
  const rigths = useMemo(() => getSelectedProjectRights(_currentProject, user), [_currentProject, user]);
  const markAsReadNotification = useMarkAsReadNotificationById();
  const [defaultOpenLightbox, setDefaultOpenLightbox] = useState(false);
  const kanban = useSelector((state) => state.kanban);
  const { task } = useGetTaskById(_task?.id)

  const handleChange = (event) => {
    setView(event.target.value);
  };

  const isSubTask = _task?.parentId;

  useEffect(() => {
    if (openAttachements) {
      setOpenAttachements(false);
      setDefaultOpenLightbox(true);
    }
  }, [openAttachements, setOpenAttachements]);

  const { project } = useSelector((state) => state.kanban);

  // const [task, _//setTask] = useState(_task);
  const [update, setUpdate] = useState(false);
  const [attachement, setAttachement] = useState([]);
  const [loadingAttachement, setLoadingAttachement] = useState(false);

  const setTask = useCallback((task) => {
    // _//setTask({
    //   ...task,
    //   projectName: _currentProject?.name || '',
    // });
  }
    , []);

  const [description, setDescription] = useState({
    description: _task?.description || '',
    desc_mention: _task?.desc_mentions || []
  });

  // console.log({ task, _task, _currentProject });

  useEffect(() => {
    markAsReadNotification(_task?.id, [
      NOTIFICATION_TYPES.TASK,
      NOTIFICATION_TYPES.TASK_MENTION,
      NOTIFICATION_TYPES.TASK_VALIDATION_DONE,
      NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE,
      NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED,
      NOTIFICATION_TYPES.TASK_BEGINING,
      NOTIFICATION_TYPES.TASK_DELETE,
      NOTIFICATION_TYPES.TASK_CREATE,
      NOTIFICATION_TYPES.TASK_VALIDATION_REJECT,
      NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER,
      NOTIFICATION_TYPES.SUB_TASK_VALIDATION_ASSIGN_USER,
      NOTIFICATION_TYPES.SUB_TASK_USER_ADDED,
      NOTIFICATION_TYPES.SUB_TASK_ADD,
      NOTIFICATION_TYPES.TASK_RETRIEVE,
      NOTIFICATION_TYPES.TASK_DUE_CHANGE,
      NOTIFICATION_TYPES.TASK_DUE_ADD,
      NOTIFICATION_TYPES.TASK_PIECE_JOINTES,
      NOTIFICATION_TYPES.TASK_PIECE_JOINTE,
      NOTIFICATION_TYPES.TASK_COMMENT_ADD,
      NOTIFICATION_TYPES.RELANCE_TASK
    ]);
  }, [_task?.id, markAsReadNotification]);

  React.useEffect(() => {
    if (project?.length) {
      const exist = project?.find((_one) => _one?.id === _task?.projectKey);
      setCurrentProject(exist);
    }
  }, [project, _task?.projectKey]);
  const handleUpdate = () => {
    setUpdate(!update);
    setTimeout(() => {
      setUpdate(false);
    }, 1000);
  };

  useEffect(() => {
    setAttachement((data) => [...data, ..._task?.attachments]);
  }, [_task?.attachments]);

  useEffect(() => {
    if (!_task?.id || !_task?.parentId) {
      //setTask(_task);
      return;
    }
    dispatch(
      getTaskById({
        taskId: isSubTask ? _task?.parentId : _task?.id,
        resolve: (__task) => {
          //setTask(__task);
          setAttachement(task?.attachments);
        },
        reject: (err) => {
          //setTask(_task);
          console.error({ err });
        }
      })
    );
  }, [_task, isSubTask, setTask, task?.attachments, update]);


  const handleChangeFile = (files) => {
    // console.log('handleChangeFile', files);
    setAttachement((data) => (uniq([...data, ...files])));
  };

  const dataInterval = currentProject ? currentProject?.dataInterval : null;

  const handleCommentChange = (comment) => {

    const newComments = [...task.comments, { ...comment }];
    dispatch(
      updateTaskWithoutProject(
        task,
        { comments: newComments },
        () => {
        }
      )
    );
  };

  const handleClose = () => {
    dispatch(
      updateTaskWithoutProject(
        task,
        { description: description.description || '', desc_mentions: description.desc_mention || [] },
        () => {
        }
      )
    );
    onClose();
    setAttachement([]);

  };
  if (!task)
    return null

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{ sx: {} }}
    >
      <DialogTitle id="scroll-dialog-title">
        <TaskItemDetailToolbar
          user={user}
          task={task}
          onUpdate={updateTaskWithoutProject}
          onClose={handleClose}
          canDelete={taskRights?.delete}
          canEdit={taskRights?.edit}
          forceUpdate={handleUpdate}
          attachement={attachement}
          setAttachement={handleChangeFile}
          loadingAttachement={loadingAttachement}
          setLoadingAttachement={setLoadingAttachement}
        />
      </DialogTitle>
      <Divider sx={{ pt: 2 }} />
      <DialogContent sx={{ pt: 2 }}>
        <Stack spacing={3} width={1} px={4}>
          <TaskTitleInput
            task={task}
            value={{ name: task?.name, editor_mentions: task?.editor_mentions }}
            onUpdate={updateTaskWithoutProject}
            disabled={!taskRights?.edit}
          />
          <Stack width={1} direction="row" spacing={3}>
            <Stack width={0.5}>
              <Sheet variant="outlined" sx={{ borderRadius: 'lg' }}>
                <Stack spacing={3} width={1} p={3}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <LabelStyle sx={{ fontSize: 17, fontWeight: 'bold' }}>Projet</LabelStyle>
                    <Stack width={1} direction="row">
                      <AddProjectToTask
                        task={task}
                        user={user}
                        onUpdate={updateTaskWithoutProject}
                        disabled={!taskRights?.edit}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <LabelStyle sx={{ fontSize: 17, fontWeight: 'bold' }}>Échéance</LabelStyle>
                    <Stack width={1} direction="row">
                      <TaskDueDate
                        task={task}
                        onUpdate={updateTaskWithoutProject}
                        disabled={!taskRights?.edit}
                        enabledDataInterval={dataInterval}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <LabelStyle sx={{ fontSize: 17, fontWeight: 'bold' }}>Intervenants</LabelStyle>
                    <TaskAssigne task={task} onUpdate={updateTaskWithoutProject} disabled={!taskRights?.edit} />
                  </Stack>
                </Stack>
              </Sheet>
            </Stack>

            <Stack width={0.5} spacing={2}>
              <Sheet variant="outlined" sx={{ borderRadius: 'lg', height: 185, overflow: 'hidden' }}>
                <Scrollbar>
                  <TaskDescriptionInput task={task} onUpdate={setDescription} disabled={!taskRights?.edit} />
                </Scrollbar>
              </Sheet>
            </Stack>
          </Stack>

          <Stack width={1}>
            <TaskSubTask
              focusId={_task?.id}
              task={task}
              rights={taskRights}
              onUpdate={updateTaskWithoutProject}
              disabled={!taskRights?.edit}
            />
          </Stack>
          <Stack width={1}>
            <TaskFileView
              task={task}
              user={user}
              onUpdate={updateTaskWithoutProject}
              disabled={!taskRights?.edit}
              setAttachement={handleChangeFile}
              attachement={attachement}
              loadingAttachement={loadingAttachement}
              setLoadingAttachement={setLoadingAttachement}
              taskRights={taskRights}
              defaultOpenLightbox={defaultOpenLightbox}
              wrapLength={6}
            />
          </Stack>
          <Stack width={1} height={'auto'} sx={{
            borderRadius: 'lg',
            overflow: 'hidden',
            bgcolor: 'grey.100'
          }} py={1}>
            <Select
              value={view}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                width: 180,
                height: 30,
                mx: 1,
                //bgcolor: 'background.paper',
                border: '0px solid grey',
                borderRadius: '4px',
                '& .MuiSelect-select': {
                  padding: '10px',
                  '&:focus': {
                    border: '0px solid grey',
                  },
                },
              }}
            >
              <MenuItem value={SELECT_OPTIONS.SIMPLE} >
                <Typography variant="caption">
                  Commentaires simples
                </Typography>
              </MenuItem>
              <MenuItem value={SELECT_OPTIONS.ALL} >
                <Typography variant="caption">
                  Tous les activites
                </Typography>
              </MenuItem>
            </Select>

            <KanbanTaskDetailsComment
              idQ="info"
              details={task}
              users={task?.canAccess || []}
              disableDelete={!rigths?.delete}
              userId={user?.id}
              name={user?.displayName}
              view={view}
              handleCommentChange={handleCommentChange}
              sx={{
                backgroundColor: 'grey.100',
                overflow: 'hidden',
                bgcolor: 'grey.100',
                height: 'fit-content',
                maxHeight: 1
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default TaskItemDetail;