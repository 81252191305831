import React, { useRef } from 'react';
import { Dialog, Paper, Container, Button } from '@mui/material';
import Center from 'src/components/Center';
import MailV2MessageDetail from 'src/section/mail_v2/MailV2MessageDetail';

export default function MailV2DetailModal({ message, open, onClose }) {
  //console.log({ message });
  const ref = useRef(null);
  return (
    <Dialog
      ref={ref}
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      fullScreen
      PaperProps={{ sx: { bgcolor: 'transparent' } }}
    >
      <Center>
        <Container maxWidth={'md'}>
          <Paper sx={{ borderRadius: 1 }} variant="outlined">
            <MailV2MessageDetail message={message} isModal onCloseModal={onClose} modalRef={ref} open={open} />
          </Paper>
        </Container>
      </Center>
    </Dialog>
  );
}
