import { alpha, ClickAwayListener, Stack, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import React from 'react';
import Markdown from 'src/components/Markdown';
import { QuillEditor } from 'src/components/editor';
import CustomEditorjs from 'src/components/editor/EditorJS/Editorjs';
import { themeColor } from 'src/constants/color';
import { useToggleV2 } from 'src/hooks/useToggle';
import { TaskType } from 'src/models/task_m';

/**
 *
 * @param {{task: TaskType}} props
 * @returns
 */
export default function TaskDescriptionInput({
  task,
  onUpdate,
  placeholder = 'Veuillez fournir une description détaillée de la tâche…',
  disabled = false
}) {
  const [openEdit, onOpenEdit, onCloseEdit] = useToggleV2();
  //BUG : Actions must be plain objects. Use custom middleware for async actions.	on onSave when click away
  const theme = useTheme();
  const [values, setValues] = React.useState({
    description: task?.description || '',
    mentions: task?.mentions || []
  });

  const handleChange = (filed, value) => {
    setValues((prev) => ({ ...prev, [filed]: value }));
    onUpdate((prev) => ({ ...prev, [filed]: value }));
  };

  const onSave = () => {
    if (!isEmpty(task?.name) && !isEmpty(values.description)) {
      onUpdate(values);
    }
  };

  return (
    <ClickAwayListener onClickAway={onSave}>
      <Stack width={1} bgcolor={alpha(themeColor.SMOKY_BLACK, 0.02)} spacing={0.5} py={1}>
        {openEdit ? (
          <ClickAwayListener onClickAway={onCloseEdit}>
            <Stack>
              <DescriptionView
                details={task}
                value={values.description}
                handleDetailChange={handleChange}
                theme={theme}
                disabled={disabled}
                placeholder={placeholder}
              />
            </Stack>
          </ClickAwayListener>
        ) : (
          <Stack minHeight={165} p={1} onClick={onOpenEdit}>
            <Markdown>{values.description}</Markdown>
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  );
}

export const DescriptionView = ({ details, value, handleDetailChange, theme, disabled = false, placeholder }) => {
  return (
    <Stack width={1} spacing={1}>
      <QuillEditor
        simple
        id="comment-compo-desc-id"
        placeholder={placeholder}
        type="bubble"
        value={value}
        noImage
        noVideo
        noFont
        noHeading
        onChange={(e) => handleDetailChange('description', e)}
        canMention
        disabled={disabled}
        setMensions={(mentions) => handleDetailChange('mentions', [...(details?.mentions || []), ...mentions])}
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[50],
          color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
          border: 'none',
          minHeight: 185,
          borderRadius: '5px',
          padding: '6px 12px',
          transition: 'all 200ms ease',
          fontSize: '13px',
          overflow: 'nowrap',
          zIndex: 1000,
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? null : grey[50],
            borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400]
          },
          '&:focus': {
            outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
            outlineOffset: 0
          },
          '& .ql-editor': {
            position: 'relative',
            padding: 0
          }
        }}
      />
    </Stack>
  );
};
